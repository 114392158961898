<template>
  <div class="static-page page">
    <div class="static-page__container">
      <h1 class="static-page__title">Вопросы и ответы</h1>
      <div class="static-page__content">
        <FaqSection />
      </div>
    </div>
  </div>
</template>

<script>
import FAQ_PAGE from "@/graphql/pages/FaqPage.graphql";
import FaqSection from "./components/FaqSection.vue";

export default {
  name: "FaqPage",
  async asyncData({ apollo, store, route }) {
    await apollo.defaultClient
      .query({
        query: FAQ_PAGE,
        variables: {
          link: route.params.link,
        },
      })
      .then(({ data }) => {
        store.state.faq_page.faqs = data.faqs;
        store.state.categories = data.categories;
        store.state.pages = data.pages;
        store.state.marketplace = data.marketplace;
        if (data.exchange_rates) store.state.exchange_rates = data.exchange_rates;
      });
  },
  components: {
    FaqSection,
  },
  metaInfo: {
    title: "Вопросы и ответы",
  },
};
</script>

<style lang="stylus">
.static-page {
  display grid
  padding 32px 15px 48px
  +below(860px) {
    padding 15px
  }

  &__container {
    max-width var(--main_width)
    width 100%
    display grid
    align-items start
    gap 24px
    margin 0 auto
  }

  &__title {
    margin 0
  }
}
</style>
